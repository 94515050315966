import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/b7146c2/components/layout-content.js";
import FontSizes from '../../components/generate/font-sizes';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Font sizes`}</h1>
    <FontSizes mdxType="FontSizes" />
    <p>{`The configuration above was generated to creating the type scale.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`fontSizes`}</inlineCode>{` array is represented in `}<inlineCode parentName="p">{`rem`}</inlineCode>{`, so you can multiply by
the `}<inlineCode parentName="p">{`base`}</inlineCode>{` to get the pixel value.`}</p>
    <p><a parentName="p" {...{
        "href": "/docs/typography/font-sizes"
      }}>{`Read more about type scales →`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      