/** @jsx jsx */
import { jsx, Container } from 'theme-ui'
import { Link } from 'gatsby'

import Layout from './layout'
import Logo from './Logo'

export default ({ children }) => (
  <Layout>
    <header sx={{ display: 'flex', alignItems: 'center' }}>
      <a sx={{ display: 'block', py: 3, px: 3 }} href="/">
        <Logo color="rgba(0,0,0,.6)" />
      </a>
      <Link
        to="/notes/logo"
        css={{
          paddingRight: 16,
          paddingLeft: 16,
          color: '#1A1A1C',
          fontSize: 14,
          fontWeight: 700,
          textDecoration: 'none',
          marginLeft: 'auto'
        }}
      >
        Notes
      </Link>
      <a
        sx={{
          fontWeight: 700,
          textDecoration: 'none',
          py: 3,
          px: 3,
          fontSize: 14,
          color: 'gray.10'
        }}
        href="https://components.ai/api/graphql"
        title="GraphQL API"
      >
        API
      </a>
      <Link
        sx={{
          textDecoration: 'none',
          fontWeight: 700,
          py: 3,
          pl: 3,
          pr: 4,
          fontSize: 14,
          color: 'gray.10'
        }}
        to="/about"
      >
        About
      </Link>
    </header>
    <div
      sx={{
        fontSize: 18,
        maxWidth: '34em',
        mr: 'auto',
        ml: 'auto',
        pb: 64,
        px: 3
      }}
    >
      {children}
    </div>
  </Layout>
)
