const sample = require('lodash.sample')
const { randomInt, randomBool } = require('../util')

const allScales = require('../../data/type-scales/all.json')
const curatedScales = require('../../data/type-scales/curated.json')
const fullScales = require('../../data/type-scales/full-scales.json')

const MAX_SIZE = 10
const MIN_SIZE = 0.6

module.exports = () => {
  if (randomBool(1 / 3)) {
    const fontSizes = sample(fullScales)
    return { fontSizes }
  }

  const base = randomInt(14, 20)
  const steps = randomInt(4, 10)
  const useCurated = randomBool()

  const scale = useCurated ? sample(curatedScales) : sample(allScales)
  const fontSizes = [1 / (scale.value * 2), 1 / scale.value, 1]

  for (let i = 1; i <= steps; i++) {
    fontSizes.push(scale.value * i)
  }

  return {
    base,
    scale,
    steps: steps + 2,
    fontSizes: fontSizes.filter(f => f <= MAX_SIZE).filter(f => f >= MIN_SIZE)
  }
}
