import React, { useState } from 'react'
import { ThemeContext } from '@emotion/core'
import { Styled } from 'theme-ui'
import { TypeScale } from '@theme-ui/style-guide'
import { RotateCcw as Rotate, ArrowDown, ArrowUp } from 'react-feather'

import { db } from '../../lib/client'

import theme from '../../theme'
import generateFontSizes from '../../lib/generate/font-sizes'

export default () => {
  const [fontSizes, setFontSizes] = useState(generateFontSizes())

  const upvote = () => {
    db.collection('components')
      .doc('font-sizes')
      .collection('votes')
      .add({
        up: true,
        data: fontSizes
      })
    setFontSizes(generateFontSizes())
  }

  const downvote = async () => {
    db.collection('components')
      .doc('font-sizes')
      .collection('votes')
      .add({
        up: false,
        data: fontSizes
      })
    setFontSizes(generateFontSizes())
  }

  return (
    <ThemeContext.Provider
      value={{
        ...theme,
        fontSizes: fontSizes.fontSizes.map(f => Math.round(f * fontSizes.base))
      }}
    >
      <Styled.a
        css={{
          cursor: 'pointer'
        }}
        onClick={() => {
          setFontSizes(generateFontSizes())
        }}
      >
        <span
          css={{
            display: 'flex',
            alignItems: 'center',
            position: 'fixed',
            top: '20px',
            right: '20px'
          }}
        >
          <Rotate alt="Refresh icon" />
          <span css={{ marginLeft: 6 }}>Regenerate</span>
        </span>
      </Styled.a>
      <Styled.a
        css={{
          cursor: 'pointer'
        }}
        onClick={() => upvote()}
      >
        <span
          css={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <ArrowUp alt="Up vote icon" />
          <span css={{ marginLeft: 6 }}>Upvote</span>
        </span>
      </Styled.a>
      <Styled.a
        css={{
          cursor: 'pointer'
        }}
        onClick={() => downvote()}
      >
        <span
          css={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <ArrowDown alt="Down vote icon" />
          <span css={{ marginLeft: 6 }}>Downvote</span>
        </span>
      </Styled.a>
      <TypeScale />
      <Styled.pre>{JSON.stringify(fontSizes, null, 2)}</Styled.pre>
    </ThemeContext.Provider>
  )
}
